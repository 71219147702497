
import Vue from 'vue'
import Component from 'vue-class-component'
import AdminUserForm from '@/views/Admin/Users/components/AdminUserForm.vue'
import { User } from '@/apis/User'
import { PartnerCategory, UserRoleId } from '@/typings/AdminTyping'

@Component({
  name: 'AddUser',
  components: {
    AdminUserForm
  }
})

export default class AddUser extends Vue {
  categoryId = null
  partnerId = null
  roles = []
  brands = []
  leasingCompanies = []
  dealerships = []
  statuses = []
  userDetails = {
    sms_on: 1,
    email_on: 1,
    type_technics: {
      is_car: 1,
      is_truck: 0,
      new_mileage: 1,
      with_mileage: 0
    }
  }

  isNotNewUser = false

  created() {
    // @ts-ignore
    this.categoryId = parseInt(this.$router.currentRoute.params.categoryId)
    // @ts-ignore
    this.partnerId = parseInt(this.$router.currentRoute.params.partnerId)
    this.fetchComboboxesForAddUser()
    if (this.categoryId && this.partnerId) {
      if (this.categoryId === PartnerCategory.Dealership) {
        // @ts-ignore
        this.userDetails.user_role_id = UserRoleId.ManagerDealership
        // @ts-ignore
        this.userDetails.companyIds = [this.partnerId]
      } else if (this.categoryId === PartnerCategory.LeasingCompany) {
        // @ts-ignore
        this.userDetails.user_role_id = UserRoleId.ManagerLeasingCompany
        // @ts-ignore
        this.userDetails.companyIds = this.partnerId
      }
    }
  }

  fetchComboboxesForAddUser() {
    User.fetchComboboxesForAddUser()
      .then(({ data }) => {
        this.roles = data.roles
        this.brands = data.brands
        this.dealerships = data.dealerships
        this.leasingCompanies = data.leasingCompanies
      })
  }

  storeUser() {
    User.storeUser(this.userDetails)
      .then(({ data }) => {
        if (data.success === true) {
          // @ts-ignore
          this.showSuccessSystemNotification(data.msg)
          this.$router.push({ name: 'Users' })
        } else {
          // @ts-ignore
          this.showErrorSystemNotification(data.msg)
        }
      })
      .catch((error) => {
        let msg
        if ('email' in error.response.data.errors) {
          msg = error.response.data.errors.email[0]
        } else if ('phone' in error.response.data.errors) {
          msg = error.response.data.errors.phone[0]
        } else {
          msg = 'Заполните поля для добавления нового пользователя'
        }

        // @ts-ignore
        this.showErrorSystemNotification(msg)
      })
  }
}
